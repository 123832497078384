import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { EsearchService } from '../esearch.service';
import { GetOrderItemResultRequest, AppSettings, Searchitem, SessionTimeOut, Jurisdiction } from '../SearchItem';
import { MatPaginator, MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription, timer } from 'rxjs';
import { Router} from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Base64 } from 'js-base64';
import { PlatformLocation, DatePipe } from '@angular/common';
import { Presearchrequest } from '../presearchrequest';
import { HttpClient } from '@angular/common/http';
import { ResultDetailDailogComponent } from '../result-detail-dailog/result-detail-dailog.component';

@Component({
  selector: 'app-result-page',
  templateUrl: './result-page.component.html',
  providers: [DatePipe]
})

export class ResultPageComponent implements OnInit, OnDestroy {
  public errorMessage: any;
  orderItemResultRequest: GetOrderItemResultRequest;
  orderItemId: number;
  orderItemSubscription: Subscription;
  kycApiTimer: any;
  baseUrl: string;
  currDate: number = Date.now();
  Jurisdictions: Jurisdiction[];

  public browserRefresh: boolean = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  subscription: Subscription;
  constructor(private http: HttpClient, private router: Router, public requestService: EsearchService, private dialog: MatDialog, @Inject('BASE_URL') baseUrl: string, private translate: TranslateService, private cookie: CookieService,
    private location: PlatformLocation) {

    this.http.get<SessionTimeOut>('assets/SessionTimeout.json').subscribe(data => {
      this.requestService.SetSessionTimeout(data);
    });

    

    if (this.requestService.SelectItem == undefined) {
      this.requestService.SelectItem = JSON.parse(this.cookie.get("selectItem"+this.cookie.get("UserId")) ) as Searchitem;
      this.requestService.PreSearchReq = JSON.parse(this.cookie.get("presearchreq" + this.cookie.get("UserId"))) as Presearchrequest;
      this.requestService.State = this.cookie.get("state");
      this.requestService.Key = this.cookie.get("key");
      this.requestService.Redirect_URI = this.cookie.get("redirect_uri");
    }
    else {
      
      this.cookie.set("selectItem" + this.cookie.get("UserId"), JSON.stringify(this.requestService.SelectItem));
      this.cookie.set("presearchreq" + this.cookie.get("UserId"), JSON.stringify(this.requestService.PreSearchReq));
    }

    http.get<Jurisdiction[]>(baseUrl + 'api/eSearch/Jurisdiction').subscribe(result => {
      this.Jurisdictions = result;
      this.requestService.PreSearchReq.JurisdictionFullName = this.Jurisdictions.filter(j => j["code"] == this.requestService.SelectItem.jurisdiction)[0]["name"];
    }, error => console.error(error));

    this.requestService.SelectItem.orderItemDate = this.requestService.SelectItem.orderItemDate + " Eastern Time";
    if (this.requestService.appsettings == undefined) {
      this.requestService.appsettings = JSON.parse(this.cookie.get("appsettings")) as AppSettings;
    }
    this.baseUrl = baseUrl;
    
    
  }

  ngOnInit() {

    this.http.get<Jurisdiction[]>('assets/JurisdictionManually.json').subscribe(data => {
      if (data.filter(j => j.Code == this.requestService.SelectItem.jurisdiction).length > 0) {
        this.translate.get('TimeoutAlert_Manually').subscribe((response: string) => {
          this.GetOrderItemStatusOneTime(response);
        });
        return;
      }
    });
    let regexp: RegExp = /^[a-zA-Z]{3}/;
    var strStart = this.requestService.SelectItem.number.match(regexp);
    if (this.requestService.SelectItem.jurisdiction == 'AB' && strStart != null) {
      this.translate.get('TimeoutAlert_AB').subscribe((response: string) => {
        this.GetOrderItemStatusOneTime(response);
      });
      return;
    }
    else {
      this.GetOrderItemStatus();
    }
  }
  ngOnDestroy() {
    if (this.orderItemSubscription != undefined)
      this.orderItemSubscription.unsubscribe();
  }

  Redirect(cancel: boolean, message: string) {
    if (this.orderItemSubscription != undefined)
      this.orderItemSubscription.unsubscribe();
    this.requestService.alertDialog = true;
    this.requestService.resultDailog = false;
    this.requestService.exitDialog = false;
    this.requestService.cancelStatus = cancel;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = message;
    this.dialog.open(ResultDetailDailogComponent, dialogConfig);
  }

  public async GetOrderItemStatus() {
    this.orderItemId = this.requestService.SelectItem.orderItemId;
    //for test on INT
    //this.orderItemId = 114338;
    console.log(this.orderItemId);
    this.orderItemResultRequest = {
      OrderItemID: this.orderItemId,
      VerifiedMeUserid: + this.cookie.get("UserId")
    };
    var kycApiUrlItem = this.baseUrl + 'api/eSearchEnvelop/OrderItemStatus';
    this.kycApiTimer = this.requestService.appsettings.kycApiTimer; //sessionStorage.getItem('kycApiTimer');
    this.orderItemSubscription = timer(0, this.kycApiTimer).pipe(
      switchMap(() => this.requestService.GetOrderItemStatus(this.orderItemResultRequest, kycApiUrlItem))
    ).subscribe(result => {
      if (result != null && result.isComplete) {
        if (this.requestService.Redirect_URI != undefined && this.requestService.Redirect_URI != "") {
          this.translate.get('SuccessAlert').subscribe((response: string) => {
            this.Redirect(false, response);
          });
        }
      }
      else {
        let nu = (Date.now() - this.currDate) / 1000;
        var resultTimeout = this.requestService.SessionTimeout[0].resultPageTimeout;
        if (nu > resultTimeout) {
          this.translate.get('TimeOutAlert').subscribe((response: string) => {
            this.Redirect(true, response);
          });
        }
      }

      //timeout
      
    });
  }

  public async GetOrderItemStatusOneTime(message) {
    this.orderItemId = this.requestService.SelectItem.orderItemId;
    //for test on INT
    //this.orderItemId = 114338;
    console.log(this.orderItemId);
    this.orderItemResultRequest = {
      OrderItemID: this.orderItemId,
      VerifiedMeUserid: + this.cookie.get("UserId")
    };
    var kycApiUrlItem = this.baseUrl + 'api/eSearchEnvelop/OrderItemStatus';
    this.kycApiTimer = this.requestService.appsettings.kycApiTimer; //sessionStorage.getItem('kycApiTimer');
    var result = await this.requestService.GetOrderItemStatus(this.orderItemResultRequest, kycApiUrlItem);
    if (status != null && result.isComplete) {
      if (this.requestService.Redirect_URI != undefined && this.requestService.Redirect_URI != "") {
        this.translate.get('SuccessAlert').subscribe((response: string) => {
          this.Redirect(false, response);
        });
      }
    }
    else {
      this.Redirect(true, message);
    }


  }
}
