import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Searchitem, OrderResponse, AppSettings, PreSearchResponse, SessionTimeOut } from './SearchItem';
import { Session } from 'protractor';
import { Presearchrequest} from './presearchrequest';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EsearchService {
  private headers: HttpHeaders;
  //private accessPointUrl: string;
  public SearchItems: Searchitem[];
  public SelectItem: Searchitem;
  SessionTimeout: SessionTimeOut;
  //public ServiceResps: Serviceresponse[];
  //public ServiceReq: Servicereq;
  public PreSearchReq: Presearchrequest;

  // public submitOrder: SubmitOrderRequest;
  public Orderresponse: OrderResponse;

  public ErrorMessage: string;

  public Redirect_URI: string;

  public State: string;
  public Key: string;
  //orderItemSubscription: Subscription;
  appsettings: AppSettings;
  isSessionTimeout: boolean = false;
  resultDailog: boolean = false;
  exitDialog: boolean = false;
  alertDialog: boolean = false;
  cancelStatus: boolean = false;
  exitPage: string;
  constructor(private http: HttpClient) {

    this.headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    let presearch: Presearchrequest;
    presearch = { ServiceId:0, SessionId: 0, VerifiedMeUserid: 0, SearchName: '', JurisdictionFullName: '', JurisdictionCode: '', SearchNumber: '', UserName: '', OfficerType: '', Role: '' };
    this.PreSearchReq = presearch;
  }
   
  public PreSearch(payload, url): Promise<any> {
    this.PreSearchReq = payload;
    return this.http.post(url, payload, { headers: this.headers }).toPromise().then(resp => { this.ErrorMessage = ""; return resp as any; }, err => {
      console.log(err.error);
      this.ErrorMessage = err.error;
      //throw err;
    });

  }

  public SetSearchItems(payload) {
    this.SearchItems = payload;
  }


  SetAppSettings(payload) {
    this.appsettings = payload;
  }
  SetSessionTimeout(payload) {
    this.SessionTimeout = payload;
  }
  public DoSearch(payload, url): Promise<any> {

    return this.http.post(url, payload, { headers: this.headers }).toPromise().then(resp => resp as PreSearchResponse).catch(err => console.log('error'));

  }
  
  /*
  public ServicesList(url): Promise<any> {
    this.ServiceReq.UserName = 'JenniferZhou20';
    return this.http.post(url, this.ServiceReq, { headers: this.headers }).toPromise().then(resp => resp as Serviceresponse[]);

  }
  */

  public GetOrderItemStatus(orderItemId, url): Promise<any> {
    return this.http.post(url, orderItemId, { headers: this.headers }).toPromise().then(resp => resp as any, err => console.log(err));
  }
  
  public SessionTimeOut(payload, url): Promise<any> {
    
    return this.http.post(url, payload, { headers: this.headers }).toPromise().then(resp => resp as any, err => {
      console.log('error');
      this.ErrorMessage = err.error.Message;
    });    
  }

  public InsertSignExitLog(payload, url): Promise<any> {

    return this.http.post(url, payload, { headers: this.headers }).toPromise().then(resp => resp as any).catch(err => console.log('error'));

  }

}







