import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { CookieService } from 'ngx-cookie-service';

import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SearchItemListComponent } from './search-item-list/search-item-list.component';
import { SearchItemListIpadComponent } from './search-item-list-ipad/search-item-list-ipad.component';
import { SearchItemListIphoneComponent } from './search-item-list-iphone/search-item-list-iphone.component';
import { ResultPageComponent } from './result-page/result-page.component';
import { ResultPageIpadComponent } from './result-page-ipad/result-page-ipad.component';
import { ResultPageIphoneComponent } from './result-page-iphone/result-page-iphone.component';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';


import { FooterComponent } from './footer/footer.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatCard, MatCardModule, MatDialogModule, MatButtonModule, MatIconModule, MatMenuModule, MatToolbarModule, MatSelectModule, MatFormFieldModule, MatOptionModule, MatInputModule } from '@angular/material';
import { ResultDetailDailogComponent } from './result-detail-dailog/result-detail-dailog.component';

// ...
import { FlexLayoutModule } from "@angular/flex-layout";
const COMPONENTS = [AppComponent, NavMenuComponent, HomeComponent, CounterComponent, FetchDataComponent, SearchItemListComponent, SearchItemListIpadComponent, SearchItemListIphoneComponent, FooterComponent, ResultPageComponent, ResultPageIpadComponent, ResultPageIphoneComponent]
const COMPONENTS_DYNAMIC = [ResultDetailDailogComponent];

@NgModule({
  declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent },
      { path: 'home', component: HomeComponent },
      { path: 'search-item-list', component: SearchItemListComponent },
      { path: 'search-item-list-ipad', component: SearchItemListIpadComponent },
      { path: 'search-item-list-iphone', component: SearchItemListIphoneComponent },
      { path: 'result-page', component: ResultPageComponent },
      { path: 'result-page-ipad', component: ResultPageIpadComponent },
      { path: 'result-page-iphone', component: ResultPageIphoneComponent }   
    ]),
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatToolbarModule,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
    MatInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
    //  SelectionModel
  ],
  providers: [CookieService],
  entryComponents: COMPONENTS_DYNAMIC,
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
