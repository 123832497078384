import { Component, OnInit, Optional, Inject, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EsearchService } from '../esearch.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderResponse, SignExitRequest } from '../SearchItem';
import { DatePipe } from '@angular/common';
import { Base64 } from 'js-base64';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-result-detail-dailog',
  templateUrl: './result-detail-dailog.component.html',
  providers: [DatePipe]
})
export class ResultDetailDailogComponent implements OnInit {
  @Output() Orderresponse: OrderResponse;
  private baseUrl: string;
  submitStatus: boolean;
  currDate: number = Date.now();
  constructor(private router: Router,
    public requestService: EsearchService,
    private dialogRef: MatDialogRef<ResultDetailDailogComponent>,
    private cookie: CookieService,
    @Optional() @Inject(MAT_DIALOG_DATA) public Data: any,
    public translate: TranslateService,
    @Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  ngOnInit(): void {
  }

  async DoSearch() {
    this.submitStatus = true;
    var kycApiUrl = this.baseUrl + 'api/eSearchEnvelop/Order';
    this.Orderresponse = await this.requestService.DoSearch(this.Data, kycApiUrl);
    if (this.Orderresponse != undefined) {
      this.requestService.SelectItem.orderItemId = this.Orderresponse.orderItems[0].orderItemID;
      this.requestService.SelectItem.orderItemDate = this.Orderresponse.responseTime;
      this.dialogRef.close();
      if (window.innerWidth <= 480) //
        this.router.navigate(['/result-page-iphone']);
      else if (window.innerWidth <= 1024) //
        this.router.navigate(['/result-page-ipad']);
      else
        this.router.navigate(['/result-page']);
    }
  }

  Confirm() {
    //modify by nan, here do not need set those binding data, otherwise it will caused page blinking.
    // this.requestService.resultDailog = false;
    //this.requestService.exitDialog = false;
    this.DoSearch();
  }

  Close() {
    //modify by nan, here do not need set those binding data, otherwise it will caused page blinking.
    //this.requestService.alertDialog = false;
    //this.requestService.resultDailog = false;
    //this.requestService.exitDialog = false;
    this.dialogRef.close();
  }

  ExitConfirm() {
    this.InsertExitLog();
    var obj = { "SessionId": this.requestService.Key, "OrderItemId": -1 };
    var response = Base64.encode(JSON.stringify(obj));
    var uri = this.requestService.Redirect_URI + "?state=" + this.requestService.State + "&responseData=" + response +"&cancel=true";
    this.cookie.deleteAll();
    window.location.href = uri;
  }
  RedirectToVerifiedMe()
  {
    var obj = { "SessionId": this.requestService.Key, "OrderItemId": this.requestService.SelectItem.orderItemId };
    var response = Base64.encode(JSON.stringify(obj));
    var uri = this.requestService.Redirect_URI + "?state=" + this.requestService.State
    uri = uri + "&responseData=" + response;
    
    if (this.requestService.cancelStatus)
      uri = uri + "&cancel=true";
    //else
    
    // this.cookie.deleteAll();
    window.location.href = uri;
  }

  InsertExitLog() {
    var logRequest: SignExitRequest = {
      browser: '',
      device: '',
      verifiedMeUserid: +this.cookie.get("UserId"),
      exitPage: this.requestService.exitPage,
      browserVersion: '',
      os: '',
      osVersion: '',
      cancelStatus: false
    }
    
    var kycApiUrl = this.baseUrl + 'api/eSearchEnvelop/InsertSignExitLog';
    this.requestService.InsertSignExitLog(logRequest, kycApiUrl);

  }
}



