import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent {

  public language: string;
  constructor(
    public translate: TranslateService, @Inject(DOCUMENT) private document: Document
  ) {
    this.language = localStorage.getItem('currentLanguage');
  }

  isExpanded = false;

 // netImage: any = require("assets/img/logo.jpg");
  
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  selectLanguage(lang)
  {
    this.translate.use(lang);
    localStorage.setItem('currentLanguage', lang);
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }

}
