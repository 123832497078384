import { Component, OnInit, EventEmitter, Input, Output, ViewChild, Optional, Inject, HostListener } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Searchitem, OrderResponse, OrderItemResponse, SubmitOrderRequest, OrderItemRequest, SignExitRequest } from '../SearchItem';
import { MatPaginator, MatTableDataSource, MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material';
import { EsearchService } from '../esearch.service';
import { resolveAny } from 'dns';
import { Router } from '@angular/router';
import { ResultDetailDailogComponent } from '../result-detail-dailog/result-detail-dailog.component';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { PlatformLocation } from '@angular/common';
import { Base64 } from 'js-base64';

@Component({
  selector: 'app-search-item-list',
  templateUrl: './search-item-list.component.html'
})
export class SearchItemListComponent implements OnInit {

  @Input() SearchItems: Searchitem[];
  @Output() Item: Searchitem;
  //public orderitem: OrderItemResponse;
  public submitOrder: SubmitOrderRequest;
  private baseUrl: string;
  pageSize: number;
  errorMessage: string;
  divResults: boolean;

  constructor(public requestService: EsearchService, @Inject('BASE_URL') baseUrl: string, private router: Router, private dialog: MatDialog, public translate: TranslateService, private cookie: CookieService,
    private location: PlatformLocation
  ) {
    this.baseUrl = baseUrl;
    this.DoSetData();
    //this.setPageSize();
    this.checkNoMatches();
  }

  ngOnInit(): void {
    this.setPageSize();
    this.dataSource.paginator = this.paginator;
    if (this.requestService.PreSearchReq.JurisdictionCode == 'QC')
      this.displayedColumns = ['select', 'CompanyName', 'CompanyNumber', 'Date', 'Status', 'NameStatus'];
    else
      this.displayedColumns = ['select', 'CompanyName', 'CompanyNumber', 'Date', 'Status', 'Type', 'Location'];
  }

  //displayedColumns: string[] = ['select', 'CompanyInfo', 'CompanyStatus','test'];
  displayedColumns: string[] = ['select', 'CompanyName', 'CompanyNumber', 'Date', 'Status', 'Type', 'Location'];
  selection = new SelectionModel<Searchitem>(false, []);
  dataSource: MatTableDataSource<Searchitem>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  DoSetData(): void {
    this.SearchItems = this.requestService.SearchItems;
    //refresh on current page
    if (this.requestService.PreSearchReq.SessionId == 0) this.DoCancel();
    //go back from 3th page
    if (this.cookie.get("selectItem" + this.cookie.get("UserId")) != "") this.DoCancel();

    this.dataSource = new MatTableDataSource(this.SearchItems);
    this.dataSource.paginator = this.paginator;
    this.clearsubmitOrder();
  }

  @HostListener('window:resize', ['$event'])
  setPageSize(event?) {
    //if (window.innerHeight <= 650) {
    //  this.pageSize = 3;
    //}
    if (window.innerHeight <= 750) {
      this.pageSize = 4;
    }
    else if (window.innerHeight > 750 && window.innerHeight <= 800) {
      this.pageSize = 5;
    }
    else if (window.innerHeight > 800 && window.innerHeight <= 850) {
      this.pageSize = 6;
    }
    else if (window.innerHeight > 850 && window.innerHeight <= 920) {
      this.pageSize = 7;
    }
    else if (window.innerHeight > 920 && window.innerHeight <= 1000) {
      this.pageSize = 8;
    }
    else {
      this.pageSize = 9;
    }
  }

  checkNoMatches() {
    if (this.SearchItems.length == 0) {
      if (this.requestService.PreSearchReq.JurisdictionCode == "QC" && this.requestService.ErrorMessage != "")
      {
        this.translate.get('TooManyResults1').subscribe((response: string) => {
          this.errorMessage = response;
        });

        if (this.requestService.PreSearchReq.SearchName != "") {
          this.errorMessage += this.requestService.PreSearchReq.SearchName;
          this.translate.get('TooManyResults2').subscribe((response: string) => {
            this.errorMessage += response;
          });
        }
        else {
          this.errorMessage += this.requestService.PreSearchReq.SearchNumber;
          this.translate.get('TooManyResults3').subscribe((response: string) => {
            this.errorMessage += response;
          });
        }
      }
      else if (this.requestService.PreSearchReq.SearchName != "") {
        this.translate.get('NoMatchesName').subscribe((response: string) => {
          this.errorMessage = response;
        });
      }
      else {
        this.translate.get('NoMatchesNumber').subscribe((response: string) => {
          this.errorMessage = response;
        });
      }
      this.divResults = false;
    }
    else if (this.requestService.PreSearchReq.JurisdictionCode=="QC" && this.SearchItems.length >= 200)
    {
      this.translate.get('TooMuchMatches').subscribe((response: string) => {
        if (this.requestService.PreSearchReq.SearchName!="")
          this.errorMessage = response.replace("{0}", this.requestService.PreSearchReq.SearchName);
        else
          this.errorMessage = response.replace("{0}", this.requestService.PreSearchReq.SearchNumber);
      });
      this.divResults = true;
    }
    else {
      this.divResults = true;
    }
  }

  RedirectToVerifyMe() {
    //var obj = { "SessionId": this.requestService.Key, "OrderItemId": -1 };
    //var response = Base64.encode(JSON.stringify(obj));
    //var uri = this.requestService.Redirect_URI + "?state=" + this.requestService.State + "&responseData=" + response;
    //window.location.href = uri;
    this.requestService.exitPage = "Page2";
    this.requestService.resultDailog = false;
    this.requestService.exitDialog = true;
    this.requestService.alertDialog = false;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = "";
    this.dialog.open(ResultDetailDailogComponent, dialogConfig);
  }

  private clearsubmitOrder = function () {
    this.submitOrder = {
      CompanyName: '',
      CompanyNumber: '',
      JurisdictionCode: '',
      username: 'JenniferZhou20',
      OrderReferenceNumber: 'verifiedme',
      OrderItemRequests:
        [
          {
            OrderItemReferenceNumber: 'verifiedme',
            ServiceID: 1
          }
        ]
    };
  };
  InsertExitLog() {
    var logRequest: SignExitRequest = {
      browser: '',
      device: '',
      verifiedMeUserid: +this.cookie.get("UserId"),
      exitPage: this.requestService.exitPage,
      browserVersion: '',
      os: '',
      osVersion: '',
      cancelStatus: true
    }

    var kycApiUrl = this.baseUrl + 'api/eSearchEnvelop/InsertSignExitLog';
    this.requestService.InsertSignExitLog(logRequest, kycApiUrl);

  }
  DoCancel() {
    this.InsertExitLog();
    var url = this.baseUrl + "home?key=" + this.cookie.get("key") + "&state=" + this.cookie.get("state") + "&redirect_uri=" + this.cookie.get("redirect_uri");
    window.location.href = url;
  }


  async DoSearch() {
    if (this.selection.selected.length > 0) {
      var selecteditem: Searchitem;
      selecteditem = (this.selection.selected)[0];
      this.requestService.SelectItem = selecteditem;
      sessionStorage.setItem('Name', selecteditem.name);
      sessionStorage.setItem('Number', selecteditem.number);
      sessionStorage.setItem('Jurisdiction', selecteditem.jurisdiction);


      this.submitOrder.CompanyName = selecteditem.name;
      this.submitOrder.CompanyNumber = selecteditem.number;
      this.submitOrder.JurisdictionCode = selecteditem.jurisdiction;
      this.submitOrder.username = sessionStorage.getItem('kycApiAccount');
      this.submitOrder.Sessionid = + this.cookie.get("SessionId");
      this.submitOrder.VerifiedMeUserid = + this.cookie.get("UserId");
      this.submitOrder.PreSearchResponse = selecteditem;

      switch (selecteditem.jurisdiction) {
        case 'AB':
          {
            if (selecteditem.standardizedEntityType.trim() == 'Business Name') {
              this.submitOrder.OrderItemRequests[0].ServiceID = 19;
            }
            else if (selecteditem.standardizedEntityType.trim() == 'Corporation')
              this.submitOrder.OrderItemRequests[0].ServiceID = 1;
            else
              this.submitOrder.OrderItemRequests[0].ServiceID = 1;
            break;
          }
        case 'ON':
          {
            if (selecteditem.standardizedEntityType.trim() == 'Business Name') {
              this.submitOrder.OrderItemRequests[0].ServiceID = 8;
            }
            else if (selecteditem.standardizedEntityType.trim() == 'Corporation')
              this.submitOrder.OrderItemRequests[0].ServiceID = 1;
            else
              this.submitOrder.OrderItemRequests[0].ServiceID = 1;
            break;
          }
        case "BC":
          {
            if (selecteditem.standardizedEntityType.trim() == 'Business Name') {
              this.submitOrder.OrderItemRequests[0].ServiceID = 8;
            }
            else if (selecteditem.standardizedEntityType.trim() == 'Corporation')
              this.submitOrder.OrderItemRequests[0].ServiceID = 1;
            else
              this.submitOrder.OrderItemRequests[0].ServiceID = 1;
            break;
          }
        default:
          {
            this.submitOrder.OrderItemRequests[0].ServiceID = 1;
            break;
          }
      }
      this.requestService.resultDailog = true;
      this.requestService.exitDialog = false;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = this.submitOrder;
      this.dialog.open(ResultDetailDailogComponent, dialogConfig);
    }
  }


}



