import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';

import { ResultPageComponent } from '../result-page/result-page.component';
@Component({
  selector: 'app-result-page-iphone',
  templateUrl: './result-page-iphone.component.html',
  providers: [DatePipe]
})
export class ResultPageIphoneComponent extends ResultPageComponent {
  
}
