import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppSettings, SessionTimeOut } from './SearchItem';
import { HttpClient } from '@angular/common/http';
import { EsearchService } from './esearch.service';
import { CookieService } from 'ngx-cookie-service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  appSeting: AppSettings;
  constructor(private requestService: EsearchService,
    public translate: TranslateService,
    private router: Router,
    private http: HttpClient,
    private cookie: CookieService,
    @Inject('BASE_URL') baseUrl: string) {
    
    translate.addLangs(['en-CA', 'fr-CA']);
    let currentLanguage = localStorage.getItem('currentLanguage') || this.translate.getBrowserCultureLang()
    if (currentLanguage != 'en-CA' && currentLanguage != 'fr-CA')
      currentLanguage = 'en-CA';
    this.translate.use(currentLanguage);
    localStorage.setItem('currentLanguage', currentLanguage)
  }


  switchLang(lang: string) {
    this.translate.use(lang);
  }

}
