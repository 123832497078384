import { Component, OnInit, EventEmitter, Input, Output, ViewChild, Optional, Inject } from '@angular/core';
import { ResultPageComponent } from '../result-page/result-page.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-result-page-ipad',
  templateUrl: './result-page-ipad.component.html',
  providers: [DatePipe]
})
export class ResultPageIpadComponent extends ResultPageComponent {
  
}
