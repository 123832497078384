import { Component, OnInit, EventEmitter, Input, Output, ViewChild, Optional, Inject, HostListener } from '@angular/core';
import { MatPaginator} from '@angular/material';
import { SearchItemListComponent } from '../search-item-list/search-item-list.component';

@Component({
  selector: 'app-search-item-list-iphone',
  templateUrl: './search-item-list-iphone.component.html'
})
export class SearchItemListIphoneComponent extends SearchItemListComponent{
  pageSizeQC: number;

  ngOnInit(): void {
    this.setPageSize();
    //this.dataSource.paginator = this.paginator;
    this.displayedColumns = ['select', 'CompanyInfoIPhone'];
  }
  displayedColumns: string[] = ['select', 'CompanyInfoIPhone'];

  @ViewChild(MatPaginator, { static: false }) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @HostListener('window:resize', ['$event'])
  setPageSize(event?) {
    //if (window.innerHeight <= 650) {
    //  this.pageSize = 2;
    //  this.pageSizeQC = 3;
    //}
    if (window.innerHeight <= 680) {
      this.pageSize = 3;
      this.pageSizeQC = 3;
    }
    else if (window.innerHeight > 680 && window.innerHeight <= 800) {
      this.pageSize = 3;
      this.pageSizeQC = 4;
    }
    else if (window.innerHeight > 800 && window.innerHeight <= 950) {
      this.pageSize = 4;
      this.pageSizeQC = 5;
    }
    else {
      this.pageSize = 5;
      this.pageSizeQC = 6;
    }
  }
}



