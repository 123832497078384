import { Component, OnInit, EventEmitter, Input, Output, ViewChild, Optional, Inject, HostListener } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { SearchItemListComponent } from '../search-item-list/search-item-list.component';

@Component({
  selector: 'app-search-item-list-ipad',
  templateUrl: './search-item-list-ipad.component.html'
})
export class SearchItemListIpadComponent extends SearchItemListComponent{

  ngOnInit(): void {
    this.setPageSize();
    //this.dataSource.paginator = this.paginator;
    this.displayedColumns = ['select', 'CompanyInfo', 'CompanyStatus'];
  }

  displayedColumns: string[] = ['select', 'CompanyInfo', 'CompanyStatus'];

  @ViewChild(MatPaginator, { static: false }) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @HostListener('window:resize', ['$event'])
  setPageSize(event?) {
    if (window.innerHeight < 830) {
      this.pageSize = 4;
    }
    else if (window.innerHeight >= 830 && window.innerHeight < 929) {
      this.pageSize = 5;
    }
    else if (window.innerHeight >= 929 && window.innerHeight < 1024) {
      this.pageSize = 6;
    }
    else if (window.innerHeight >= 1024 && window.innerHeight < 1120) {
      this.pageSize = 7;
    }
    else if (window.innerHeight >= 1120 && window.innerHeight < 1215) {
      this.pageSize = 8;
    }
    else {
      this.pageSize = 9;
    }
  }
}



