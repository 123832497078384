import { Component, EventEmitter, Input, Output, OnInit, Inject, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, Resolve, ActivatedRoute } from '@angular/router';
import { Presearchrequest, LogRoleMap, SessionTimeOutResponse } from '../presearchrequest';
import { EsearchService } from '../esearch.service';
import { Searchitem, Jurisdiction, AppSettings, Role, OfficerType, VerifiedMeUserTimeOutRequest, SignExitRequest } from '../SearchItem';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Base64 } from 'js-base64';
import { FormGroup, FormControl, Validators, NgForm, FormBuilder } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { ResultDetailDailogComponent } from '../result-detail-dailog/result-detail-dailog.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})


export class HomeComponent implements OnInit {

  public presearchreq: Presearchrequest;
  public Items: Searchitem[];
  public errorMessage: any;
  Jurisdictions: Jurisdiction[];
  appSeting: AppSettings;
  registryOutageCheck: boolean;
  searchStatus: boolean;
  OfficerTypes: OfficerType[];
  Roles: Role[];
  isOfficeType: boolean = false;
  isOfficeOtherType: boolean = false;
  otherOfficeType: string = '';
  presearchForm: FormGroup;
  divRequest: boolean;
  divProcess: boolean;
  baseUrl: string;
  showHint: boolean = true;
  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string, private requestService: EsearchService, private router: Router, public translate: TranslateService,
    private localUri: ActivatedRoute, private cookie: CookieService,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService) {
    //if (this.requestService.isSessionTimeout == false) {
    this.baseUrl = baseUrl;
    
    //this.cookie.deleteAll();

    http.get<Jurisdiction[]>(baseUrl + 'api/eSearch/Jurisdiction').subscribe(result => {
      this.Jurisdictions = result;
    }, error => console.error(error));

    http.get<AppSettings>(baseUrl + 'api/eSearch/kycAPI').subscribe(result => {
      this.appSeting = result;
      this.cookie.set("appsettings", JSON.stringify(this.appSeting));

      var userTimeout: VerifiedMeUserTimeOutRequest = {
        License: ''
      }
      this.localUri.queryParams.subscribe(queryParams => {
        if (queryParams["key"] != undefined) {
          userTimeout.License = queryParams["key"];
          this.requestService.Key = queryParams["key"];
          this.cookie.set("key", this.requestService.Key);
        }

        if (queryParams["redirect_uri"] != undefined) {
          this.requestService.Redirect_URI = queryParams["redirect_uri"];
          this.cookie.set("redirect_uri", this.requestService.Redirect_URI);
        }

        if (queryParams["state"] != undefined) {
          this.requestService.State = queryParams["state"];
          this.cookie.set("state", this.requestService.State);
        }

        if (queryParams["ui_locales"] != undefined) {
          if (queryParams["ui_locales"].startsWith("fr")) {
            localStorage.setItem('currentLanguage', "fr-CA");
            this.translate.use("fr-CA");
          }
          else {
            localStorage.setItem('currentLanguage', "en-CA");
            this.translate.use("en-CA");
          }
        }

      });
      this.sessionTimeOut(userTimeout);

    }, error => console.error(error));



    // sessionStorage.setItem('kycApiUrl', this.appSeting.kycApiUrl);
    this.http.get<Role[]>('assets/Role.json').subscribe(data => {
      this.Roles = data;

    });

    this.http.get<OfficerType[]>('assets/OfficerType.json').subscribe(data => {
      this.OfficerTypes = data;
    });
    this.preSearchFormGroup();
    this.clearPresearchRequest();
    //}
    //   else {
    //    this.translate.get('UserInactive').subscribe((response: string) => {
    //      this.errorMessage = response;
    //    });
    //   this.divRequest = false;
    //   this.divProcess = false;
    // }

  }
  preSearchFormGroup() {
    this.presearchForm = new FormGroup({
      SearchName: new FormControl('', Validators.compose([Validators.minLength(3)])),
      SearchNumber: new FormControl({ value: '', disabled: true }, Validators.compose([Validators.pattern("^[ a-zA-Z0-9]*"), Validators.maxLength(15), Validators.minLength(3)])),
      drpJurisdiction: new FormControl('', Validators.required),
      drpRole: new FormControl('', Validators.required),
      drpOfficeType: new FormControl(),
      otherOfficeType: new FormControl()
    });
  }
  ngOnInit() {
  }

  RedirectToVerifyMe() {
    this.requestService.exitPage = "Page1";
    this.requestService.resultDailog = false;
    this.requestService.exitDialog = true;
    this.requestService.alertDialog = false;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = "";
    this.dialog.open(ResultDetailDailogComponent, dialogConfig);
  }
  SessionOutRedirectToVerifyMe() {
    /*
    var obj = { "SessionId": this.requestService.Key, "OrderItemId": -1 };
    var response = Base64.encode(JSON.stringify(obj));
    var uri = this.requestService.Redirect_URI + "?state=" + this.requestService.State + "&responseData=" + response + "&cancel=true";
    this.cookie.deleteAll();
    window.location.href = uri;
    */
    
    var obj = { "SessionId": this.cookie.get("key"), "OrderItemId": -1 };
    var response = Base64.encode(JSON.stringify(obj));
    var uri = this.cookie.get("redirect_uri") + "?state=" + this.cookie.get("state") + "&responseData=" + response + "&cancel=true";
    this.cookie.deleteAll();
    window.location.href = uri;
    
  }
  async sessionTimeOut(userTimeout: VerifiedMeUserTimeOutRequest) {

    var urlSessionTimeOut = this.baseUrl + 'api/eSearchEnvelop/CheckVerifiedMeUserTimeOut';
    this.requestService.SessionTimeOut(userTimeout, urlSessionTimeOut).then(data => {

      let result: SessionTimeOutResponse = data;
      if (result != undefined) {
        if (result.timeOut == 1) {
          this.divRequest = false;
          this.divProcess = false;
          this.translate.get('UserInactive').subscribe((response: string) => {
            this.errorMessage = response;
          });
        } else if (result.timeOut == -1) {
          this.translate.get('LicenseInvalid').subscribe((response: string) => {
            this.errorMessage = response;
          });
          this.divRequest = false;
          this.divProcess = false;
        }
        else if (result.timeOut == 0) {
          this.LogInsert(result.verifiedMeUserId);
          this.divRequest = true;
          this.cookie.set("SessionId", result.sessionID.toString());
          this.cookie.set("UserId", result.verifiedMeUserId.toString());
          this.cookie.delete("selectItem" + this.cookie.get("UserId"));
          this.cookie.delete("presearchreq" + this.cookie.get("UserId"));
          if (result.selectedItem != undefined && result.selectedItem.orderItemId > 0) {
            //there are redirect to result page
            this.requestService.SelectItem = result.selectedItem;
            this.requestService.PreSearchReq.JurisdictionFullName = this.Jurisdictions.filter(j => j["code"] == this.requestService.SelectItem.jurisdiction)[0]["name"];
            this.requestService.PreSearchReq.JurisdictionCode = result.selectedItem.jurisdiction;
            if (window.innerWidth <= 480) //
              this.router.navigate(['/result-page-iphone']);
            else if (window.innerWidth <= 1024) //
              this.router.navigate(['/result-page-ipad']);
            else
              this.router.navigate(['/result-page']);
          }
          //this.requestService.SelectItem.orderItemID
        }
      } else {
        this.divRequest = false;
        this.divProcess = false;
        this.translate.get('UserInactive').subscribe((response: string) => {
          this.errorMessage = response;
        });
      }
    });
  }

  buttonDisabled: boolean = false;

  LogInsert(verifiedMeUserid) {
    var deviceInfo = this.deviceService.getDeviceInfo();
    var logRequest: SignExitRequest = {
      browser: deviceInfo.browser,
      device: deviceInfo.device,
      verifiedMeUserid: verifiedMeUserid,
      browserVersion: deviceInfo.browser_version,
      os: deviceInfo.os,
      osVersion: deviceInfo.os_version,
      exitPage: '',
      cancelStatus: false
    }
    var kycApiUrl = this.baseUrl + 'api/eSearchEnvelop/InsertSignExitLog';
    this.requestService.InsertSignExitLog(logRequest, kycApiUrl);
  }

  private clearPresearchRequest = function () {
    this.presearchreq = {
      SearchName: '',
      SearchNumber: '',
      JurisdictionCode: '',
      UserName: '',
      Role: '',
      OfficerType: ''
    };
  };
  onRoleSelected(data) {
    (<HTMLDivElement>document.getElementById('drpRole')).style.color = "black";
    if (data == 'Officer') {
      this.isOfficeType = true;
      this.otherOfficeType = '';
      this.presearchreq.OfficerType = '';
      this.presearchForm.get('drpOfficeType').setValidators(Validators.required)
      this.presearchForm.get('drpOfficeType').updateValueAndValidity();
    }
    else {
      this.isOfficeOtherType = false;
      this.isOfficeType = false;
      this.presearchForm.get('drpOfficeType').clearValidators();
      this.presearchForm.get('drpOfficeType').updateValueAndValidity();
    }
  }

  onOfficerTypeSelected(data) {
    (<HTMLDivElement>document.getElementById('drpOfficeType')).style.color = "black";
    if (data == 'Other') {
      this.isOfficeOtherType = true;
      this.presearchForm.get('otherOfficeType').setValidators(Validators.required)
      this.presearchForm.get('otherOfficeType').updateValueAndValidity();
    }
    else {
      this.isOfficeOtherType = false;
      this.otherOfficeType = '';
      this.presearchForm.get('otherOfficeType').clearValidators()
      this.presearchForm.get('otherOfficeType').updateValueAndValidity();
    }
  }
  onJurisdictionChange($event) {
    //console.log(this.presearchreq.JurisdictionCode);
    // I want to do something here with the new selectedDevice, but what I
    // get here is always the last selection, not the one I just selected.
    //(<HTMLDivElement>document.getElementById("PreSearch")).classList.remove("buttonSubmitGrey");
    //(<HTMLDivElement>document.getElementById("PreSearch")).classList.add("buttonSubmit");
    (<HTMLDivElement>document.getElementById('drpJurisdiction')).style.color = "black";
    this.errorMessage = "";
    this.registryOutageCheck = false;
    this.presearchreq.JurisdictionFullName = this.Jurisdictions.filter(j => j["code"] == this.presearchreq.JurisdictionCode)[0]["name"];

  }

  changeRadio(data: any) {
    if (data == 'Name') {
      this.presearchForm.get('SearchName').reset();
      this.presearchForm.get('SearchNumber').disable();
      this.presearchForm.get('SearchName').enable();
      this.clearPresearchRequest();
      this.isOfficeOtherType = false;
      this.isOfficeType = false;
      this.presearchForm.get('drpJurisdiction').reset('');
      this.presearchForm.get('drpRole').reset('');
      this.presearchForm.get('drpOfficeType').reset('');
      this.presearchForm.get('otherOfficeType').reset('');
      this.otherOfficeType = '';
      this.presearchForm.get('otherOfficeType').clearValidators()
      this.presearchForm.get('otherOfficeType').updateValueAndValidity();
    } else {
      this.clearPresearchRequest();
      this.presearchForm.get('SearchNumber').reset();
      this.presearchForm.get('SearchName').disable();
      this.presearchForm.get('SearchNumber').enable();
      this.isOfficeOtherType = false;
      this.isOfficeType = false;
      this.presearchreq.Role = '';
      this.presearchForm.get('drpJurisdiction').reset('');
      this.presearchForm.get('drpRole').reset('');
      this.presearchForm.get('drpOfficeType').reset('');
      this.presearchForm.get('otherOfficeType').reset('');
      this.otherOfficeType = '';
      this.presearchForm.get('otherOfficeType').clearValidators()
      this.presearchForm.get('otherOfficeType').updateValueAndValidity();
    }
  }

  public async DoPreSearch(presearchreq) {

    if (this.presearchForm.valid) {
      if (this.otherOfficeType != '') {
        this.presearchreq.OfficerType = this.otherOfficeType;
      }
      this.divRequest = false;
      this.divProcess = true;
      this.requestService.ErrorMessage = "";

      this.presearchreq.UserName = this.appSeting.kycApiAccount;//  'JenniferZhou20';
      var url = this.baseUrl + 'api/eSearchEnvelop/PreSearch';
      this.requestService.SetAppSettings(this.appSeting);
      sessionStorage.setItem('kycApiUrl', this.appSeting.kycApiUrl);
      sessionStorage.setItem('kycApiTimer', this.appSeting.kycApiTimer);
      sessionStorage.setItem('kycApiAccount', this.appSeting.kycApiAccount);

      this.presearchreq.ServiceId = 1;
      this.presearchreq.SessionId = + this.cookie.get("SessionId");
      this.presearchreq.VerifiedMeUserid = + this.cookie.get("UserId");
      this.errorMessage = '';
      this.searchStatus = true;
      this.requestService.PreSearch(this.presearchreq, url).then(preresult => {
        this.searchStatus = false;
        if (preresult == undefined) {
          this.requestService.SetSearchItems([]);

          if (window.innerWidth <= 480)
            this.router.navigate(['/search-item-list-iphone']);
          else if (window.innerWidth <= 1024)
            this.router.navigate(['/search-item-list-ipad']);
          else
            this.router.navigate(['/search-item-list']);
        }
        else {
          if (preresult.checkRegOutageResult == false) {
            /*if (preresult.searchItems == null) {
              this.translate.get('TooManyResults1').subscribe((response: string) => {
                this.errorMessage = response;
              });

              if (this.presearchreq.SearchName != "") {
                this.errorMessage += this.presearchreq.SearchName;
                this.translate.get('TooManyResults2').subscribe((response: string) => {
                  this.errorMessage += response;
                });
              }
              else {
                this.errorMessage += this.presearchreq.SearchNumber;
                this.translate.get('TooManyResults3').subscribe((response: string) => {
                  this.errorMessage += response;
                });
              }
              this.divRequest = true;
              this.divProcess = false;
              this.clearPresearchRequest();
              this.preSearchFormGroup();
              this.isOfficeOtherType = false;
              this.isOfficeType = false;
              this.otherOfficeType = '';
            }

            else {
            */
              this.http.get('assets/PresearchUnsubscribe.json').subscribe(data => {
                console.log("pre search result return");
                let results: Searchitem[] = preresult.searchItems;
                this.Items = results.filter(r => !data["StandardizedEntityTypes"].includes(r.standardizedEntityType));
                console.log("pre search result filter");
                if (this.requestService.ErrorMessage != undefined && this.requestService.ErrorMessage != "") {

                  this.errorMessage = this.requestService.ErrorMessage;
                  //      (<HTMLDivElement>document.getElementById("divRequest")).style.display = "";
                  this.divRequest = true;
                  this.divProcess = false;
                  //  (<HTMLDivElement>document.getElementById("divProcess")).style.display = "none";
                }

                if (this.Items != undefined) {
                  this.requestService.SetSearchItems(this.Items);

                  if (window.innerWidth <= 480)
                    this.router.navigate(['/search-item-list-iphone']);
                  else if (window.innerWidth <= 1024)
                    this.router.navigate(['/search-item-list-ipad']);
                  else
                    this.router.navigate(['/search-item-list']);

                }
                else { console.log("No result"); }
              });
            //}
          }
          else {
            this.registryOutageCheck = true;
            this.translate.get('RegistryOutageCheck1').subscribe((response: string) => {
              this.errorMessage = response;
            });
            this.errorMessage += this.presearchreq.JurisdictionFullName;
            this.translate.get('RegistryOutageCheck2').subscribe((response: string) => {
              this.errorMessage += response;
            });
            this.divRequest = true;
            this.divProcess = false;
            this.clearPresearchRequest();
            this.preSearchFormGroup();
            this.isOfficeOtherType = false;
            this.isOfficeType = false;
            this.otherOfficeType = '';
          }
        }
      });
    }
  }

}

